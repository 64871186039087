// CouponMasterAddEdit

import React, { useEffect, useState, useContext } from 'react';
import { Button, Modal, Form, Row, Col, Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FaEdit, FaTrash, FaMinus, FaPlus, FaArrowLeft, FaCopy, FaMapPin } from 'react-icons/fa';
import { BsPinFill, BsPin } from 'react-icons/bs';
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import APIServicenew from '../../utils/APIGeneralService';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from '../../context/UserContextProvider';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { formateDateAndTime } from '../../utils/Helper';
import Select from 'react-select';

const CouponMasterAddEdit = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const editDataId = searchParams.get('editDataId');
  const cookies = new Cookies();
  let token = cookies.get('accessToken');
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState('');
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState('');
  const [titleHi, setTitleHi] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionHi, setDescriptionHi] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [perUserLimit, setPerUserLimit] = useState('');
  const [totalLimit, setTotalLimit] = useState('');
  const [couponType, setCouponType] = useState('');
  const [couponPercent, setCouponPercent] = useState('');
  const [couponFlat, setCouponFlat] = useState('');
  const [globalCouponType, setGlobalCouponType] = useState('');
  const [globalCouponPercent, setGlobalCouponPercent] = useState('');
  const [globalCouponFlat, setGlobalCouponFlat] = useState('');
  const [expireDate, setExpireDate] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [termsAndConditions, setTermsAndConditions] = useState([{  content: '', contentHi:'' }]);


  const addInputField = () => {
    setTermsAndConditions([...termsAndConditions, { content: '', contentHi:'' }]);
  };

  const removeInputField = index => {
    const values = [...termsAndConditions];
    values.splice(index, 1);
    setTermsAndConditions(values);
  };

  const applliedOnData = [
    {
      id: 'puja',
      name: 'Puja',
    },
    {
      id: 'chadawa',
      name: 'Chadawa',
    },
    {
      id: 'report',
      name: 'Report',
    },
    {
      id: 'ask_question',
      name: 'Ask Question',
    },
    {
      id: 'call',
      name: 'Call',
    },
    {
      id: 'chat',
      name: 'Chat',
    },
  ];

  const options = applliedOnData.map(item => ({
    value: item.id,
    label: item.name,
  }));

  const handleSelectChange = selectedOptions => {
    setSelectedOption(selectedOptions ? selectedOptions.map(option => option.value) : []);
    setErrors(prevErrors => ({ ...prevErrors, selectedOption: '' }));
  };

  useEffect(() => {
    if (editDataId) {
      showEditData();
    }
  }, []);

  useEffect(() => {
    getEditData();
  }, [editDataId]);

  const handleReset = () => {
    setErrors({});
    setTitle('');
    setTitleHi('');
    setDescription('');
    setDescriptionHi('');
    setCouponCode('');
    setPerUserLimit('');
    setTotalLimit('');
    setCouponType('');
    setCouponPercent('');
    setCouponFlat('');
    setGlobalCouponType('');
    setGlobalCouponPercent('');
    setGlobalCouponFlat('');
    setExpireDate('');
    setSelectedOption([]);
    setTermsAndConditions([{  content: '',contentHi: '' }])
  };

  const showEditData = item => {
    setSelectedId(item?._id);
    setTitle(item?.title ?? '');
    setTitleHi(item?.titleHi ?? '');
    setDescription(item?.description ?? '');
    setDescriptionHi(item?.descriptionHi ?? '');
    setCouponCode(item?.couponCode ?? '');
    setPerUserLimit(item?.perUserLimit ?? '');
    setTotalLimit(item?.totalLimit ?? '');
    setCouponType(item?.couponType ?? '');
    {
      item?.couponType === 'percent' && setCouponPercent(item?.value ?? '');
    }
    {
      item?.couponType === 'flat' && setCouponFlat(item?.value ?? '');
    }
    setGlobalCouponType(item?.globalCouponType ?? '');
    {
      item?.globalCouponType === 'percent' && setGlobalCouponPercent(item?.globalValue ?? '');
    }
    {
      item?.globalCouponType === 'flat' && setGlobalCouponFlat(item?.globalValue ?? '');
    }

    setExpireDate(item?.expireDate ? new Date(item?.expireDate) : null);
    setSelectedOption(item?.appliedOn ?? []);
    setTermsAndConditions(item?.termsAndConditions ?? []);
    
  };

  const getEditData = async () => {
    if (editDataId) {
      let res = await APIServices.get(`couponMaster/getDetailById/${editDataId}`, headers);
      if (res?.status === 200) {
        let apiData = res?.data;
        if (apiData?.status) {
          showEditData(apiData?.data);
        }
      }
    }
  };

  const validate = () => {
    const newErrors = {};

    if (!title) newErrors.title = 'Title is required';
    if (!titleHi) newErrors.titleHi = 'Hindi Title is required';
    if (!description) newErrors.description = 'Description is required';
    if (!descriptionHi) newErrors.descriptionHi = 'Hindi Description is required';

    if (!couponCode) {
      newErrors.couponCode = 'Coupon Code is required';
    } else if (/\s/.test(couponCode)) {
      newErrors.couponCode = 'Coupon Code should not contain spaces';
    } else if (couponCode.length > 8 || couponCode.length < 6) {
      newErrors.couponCode = 'Coupon Code should be 6 to 8 characters';
    }
    if (!perUserLimit) newErrors.perUserLimit = 'Per User Limit Code is required';
    if (!totalLimit) newErrors.totalLimit = 'Total Limit Code is required';
    if (!couponType) newErrors.couponType = 'Coupon Type is required';
    if (couponType === 'percent') {
      if (!couponPercent) newErrors.couponPercent = 'Percent is required';
    }
    if (couponType === 'flat') {
      if (!couponFlat) newErrors.couponFlat = 'Flat is required';
    }
    // if (!globalCouponType) newErrors.globalCouponType = 'Global Coupon Type is required';
    if (globalCouponType === 'percent') {
      if (!globalCouponPercent) newErrors.globalCouponPercent = 'Percent is required';
    }
    if (globalCouponType === 'flat') {
      if (!globalCouponFlat) newErrors.globalCouponFlat = 'Flat is required';
    }
    if (!expireDate || expireDate === null) newErrors.expireDate = 'Please select Expiry date.';

    if (selectedOption?.length === 0)  newErrors.selectedOption = 'Please select at least one option.';
    
    termsAndConditions.forEach((field, index) => {
      if (!field.content) newErrors[`content-${index}`] = 'English Content is required';
      if (!field.contentHi) newErrors[`contentHi-${index}`] = 'Hindi Content is required';
    });
  

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validate()) {
      const expiryD = formateDateAndTime(expireDate);
        let  payload = {
        title: title,
        titleHi: titleHi,
        description: description,
        descriptionHi: descriptionHi,
        couponCode: couponCode,
        perUserLimit: perUserLimit,
        totalLimit: totalLimit,
        couponType: couponType,
        // globalCouponType: globalCouponType,
        expireDate: expiryD,
        appliedOn: selectedOption,
        termsAndConditions : termsAndConditions
      };
      if (couponType === 'percent') {
        payload.value = couponPercent;
      }
      if (couponType === 'flat') {
        payload.value = couponFlat;
      }
       if (globalCouponType) {
        payload.globalCouponType = globalCouponType
       }

      if (globalCouponType === 'percent') {
        payload.globalValue = globalCouponPercent;
      }
      if (globalCouponType === 'flat') {
        payload.globalValue = globalCouponFlat;
      }

      try {
        const response = await APIServices.post('couponMaster/create', payload, headers);
        if (response.data.status === true) {
          toast.success(response?.data?.message);
          setLoading(false);
          handleReset();
          Navigate('/couponMaster');
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) { 
        toast.error('Something went wrong!');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUpdate = async e => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      const expiryD = formateDateAndTime(expireDate);

      let payload = {
        title: title,
        titleHi: titleHi,
        description: description,
        descriptionHi: descriptionHi,
        couponCode: couponCode,
        perUserLimit: perUserLimit,
        totalLimit: totalLimit,
        couponType: couponType,
        // globalCouponType: globalCouponType,
        expireDate: expiryD,
        appliedOn: selectedOption,
        termsAndConditions : termsAndConditions
      };

      if (couponType === 'percent') {
        payload.value = couponPercent;
      }
      if (couponType === 'flat') {
        payload.value = couponFlat;
      }

      if (globalCouponType) {
        payload.globalCouponType = globalCouponType
       }

      if (globalCouponType === 'percent') {
        payload.globalValue = globalCouponPercent;
      }
      if (globalCouponType === 'flat') {
        payload.globalValue = globalCouponFlat;
      }
      try {
        const response = await APIServices.put(`couponMaster/editOne/${editDataId}`, payload, headers);
        if (response.data.status === true) {
          toast.success(response?.data?.message);
          Navigate('/couponMaster');
          setLoading(false);
        } else {
          toast.error(response?.data?.message);
        }
      } catch (error) {
        toast.error('Something went wrong!');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleGenerateCode = () => {
    const generateRandomCode = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < 8; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };
  
    const newCode = generateRandomCode();
    setCouponCode(newCode);
    setErrors((prevErrors) => ({ ...prevErrors, couponCode: '' })); // Clear errors if any
  };

  return (
    <div>
      <>
        <div className='main aarti_section'>
          <div className='gita_quote_heading'>
            <div className='container d-flex align-items-center justify-content-between'>
              <h4 className='hd-list-f left-q'>
                <FaArrowLeft
                  className='hd-cr '
                  style={{ marginRight: '15px' }}
                  size={25}
                  onClick={() => Navigate(-1)}
                />
                {selectedId ? 'Edit' : 'Add'} Coupon
              </h4>
            </div>
          </div>
        </div>
      </>

      <div className='container'>
        <div className='notifiction_form box-shadow br-6 p-20 my-4'>
          <Form onSubmit={e => `${editDataId ? handleUpdate(e) : handleSubmit(e)}`}>
            <Row>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Title
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Title...'
                      name='title'
                      className='form-control'
                      value={title}
                      isInvalid={!!errors?.title}
                      onChange={e => {
                        setTitle(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleHiInput'>
                  <Form.Label className='required' column sm='4'>
                    Title Hindi
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Title...'
                      name='titleHi'
                      className='form-control'
                      value={titleHi}
                      isInvalid={!!errors?.titleHi}
                      onChange={e => {
                        setTitleHi(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, titleHi: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors.titleHi}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Description
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Description...'
                      name='description'
                      className='form-control'
                      as='textarea'
                      style={{ height: 80 }}
                      value={description}
                      isInvalid={!!errors?.description}
                      onChange={e => {
                        setDescription(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, description: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.description}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Description Hindi
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      placeholder='Description Hindi...'
                      name='descriptionHi'
                      as='textarea'
                      style={{ height: 80 }}
                      className='form-control'
                      value={descriptionHi}
                      isInvalid={!!errors?.descriptionHi}
                      onChange={e => {
                        setDescriptionHi(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, descriptionHi: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.descriptionHi}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>

              <Col sm={12} md={12} lg={8}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='3'>
                    Coupon Code
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Control
                      type='text'
                      placeholder='Coupon Code...'
                      name='couponCode'
                      className='form-control'
                      readOnly={!!editDataId}
                      value={couponCode}
                      isInvalid={!!errors?.couponCode}
                      onChange={e => {
                        setCouponCode(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, couponCode: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.couponCode}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={4}>
              <button
                  type="button"
                  // className="Et-btn add-btn1 btn-success1 mt-3"
                  style={{ backgroundColor: 'green' }}
                  className={editDataId ? 'reset-btn as_btn me-0 ms-0 mt-3 px-4 py-1' : 'as_btn me-0 ms-0 mt-3 px-4 py-1'}
                  // className={`as_btn me-0 ms-0 mt-3 px-4 py-1 ${editDataId ? 'reset-btn' : ''}`}
                  onClick={handleGenerateCode}
                  disabled={!!editDataId}
                  // reset-btn
                >
                  Generate Code
                </button>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Per User Limit
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='number'
                      placeholder='Per User Limit...'
                      name='perUserLimit'
                      className='form-control'
                      value={perUserLimit}
                      isInvalid={!!errors?.perUserLimit}
                      onChange={e => {
                        setPerUserLimit(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, perUserLimit: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.perUserLimit}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                  <Form.Label className='required' column sm='4'>
                    Total Limit
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='number'
                      placeholder='Total Limit...'
                      name='totalLimit'
                      className='form-control'
                      value={totalLimit}
                      isInvalid={!!errors?.totalLimit}
                      onChange={e => {
                        setTotalLimit(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, totalLimit: '' }));
                      }}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.totalLimit}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>

              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='couponType'>
                  <Form.Label column sm='4' className='required'>
                    Coupon Type (INR)
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr form-ch'
                      type='text'
                      name='couponType'
                      value={couponType}
                      // onChange={(e)=>(setSelectedValue(e.target.value))}
                      onChange={e => {
                        setCouponType(e.target.value);
                        if (e.target.value) {
                          setErrors(prevErrors => ({ ...prevErrors, couponType: null }));
                        }
                      }}
                      isInvalid={!!errors?.couponType}
                    >
                      <option value=''>Select Coupon</option>
                      <option value='percent'>Percent</option>
                      <option value='flat'>Flat</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>{errors?.couponType}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={6}>
                {couponType === 'percent' && (
                  <>
                    <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                      <Form.Label className='required' column sm='4'>
                        Percent
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          type='number'
                          placeholder='Percent...'
                          name='percent'
                          className='form-control'
                          value={couponPercent}
                          isInvalid={!!errors?.couponPercent}
                          onChange={e => {
                            setCouponPercent(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, couponPercent: '' }));
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.couponPercent}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}
                {couponType === 'flat' && (
                  <>
                    <Form.Group as={Row} className='mb-3 mt-3' controlId='formTitleInput'>
                      <Form.Label className='required' column sm='4'>
                        Flat
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          type='number'
                          placeholder='Flat...'
                          name='flat'
                          className='form-control'
                          value={couponFlat}
                          isInvalid={!!errors?.couponFlat}
                          onChange={e => {
                            setCouponFlat(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, couponFlat: '' }));
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.couponFlat}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Col>

              <Col sm={12} md={12} lg={6}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId='globalCouponType'>
                  <Form.Label column sm='4' className=''>
                    Global Coupon Type
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Select
                      aria-label='label-selected-menu'
                      className='fw-bold label-selected-menu text-capitalize  w-50 hd-cr form-ch'
                      type='text'
                      name='globalCouponType'
                      value={globalCouponType}
                      onChange={e => {
                        setGlobalCouponType(e.target.value);
                        // if (e.target.value) {
                        //   setErrors(prevErrors => ({ ...prevErrors, globalCouponType: null }));
                        // }
                      }}
                      // isInvalid={!!errors?.globalCouponType}
                    >
                      <option value=''>Select Coupon</option>
                      <option value='percent'>Percent</option>
                      <option value='flat'>Flat</option>
                    </Form.Select>
                    {/* <Form.Control.Feedback type='invalid'>{errors?.globalCouponType}</Form.Control.Feedback> */}
                  </Col>
                </Form.Group>
              </Col>

              <Col sm={12} md={12} lg={6}>
                {globalCouponType === 'percent' && (
                  <>
                    <Form.Group as={Row} className='mb-3 mt-3' controlId='globalCouponPercent'>
                      <Form.Label className='' column sm='4'>
                        Percent
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          type='number'
                          placeholder='Percent...'
                          name='percent'
                          className='form-control'
                          value={globalCouponPercent}
                          isInvalid={!!errors?.globalCouponPercent}
                          onChange={e => {
                            setGlobalCouponPercent(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, globalCouponPercent: '' }));
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.globalCouponPercent}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}

                {globalCouponType === 'flat' && (
                  <>
                    <Form.Group as={Row} className='mb-3 mt-3' controlId='globalCouponFlat'>
                      <Form.Label className='' column sm='4'>
                        Flat
                      </Form.Label>
                      <Col sm='8'>
                        <Form.Control
                          type='number'
                          placeholder='Flat...'
                          name='flat'
                          className='form-control'
                          value={globalCouponFlat}
                          isInvalid={!!errors?.globalCouponFlat}
                          onChange={e => {
                            setGlobalCouponFlat(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, globalCouponFlat: '' }));
                          }}
                        />
                        <Form.Control.Feedback type='invalid'>{errors?.globalCouponFlat}</Form.Control.Feedback>
                      </Col>
                    </Form.Group>
                  </>
                )}
              </Col>

              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className='mb-3 mt-3' controlId=''>
                  <Form.Label className='required' column sm='2'>
                    Expire Date
                  </Form.Label>
                  <Col sm='10'>
                    <DatePicker
                      showTimeSelect
                      placeholderText={'Please select a date'}
                      className='form-control react-datepicker__input-container'
                      selected={expireDate}
                      onChange={date => {
                        setExpireDate(date);
                        setErrors(prevErrors => ({ ...prevErrors, expireDate: '' }));
                      }}
                      dateFormat='MMMM d, yyyy h:mmaa'
                    />
                    <p className='errorVal'>{errors?.expireDate}</p>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={12} md={12} lg={12}>
                <Form.Group as={Row} className='mb-3 mt-2' controlId='couponType'>
                  <Form.Label column sm='2' className='required'>
                    Appllied On
                  </Form.Label>
                  <Col sm='10'>
                    <Select
                      isMulti
                      className=' mt-0'
                      classNamePrefix='react-select'
                      options={options}
                      onChange={handleSelectChange}
                      placeholder='Search...'
                      value={options?.filter(option => selectedOption.includes(option.value))}
                      isClearable
                    />
                    {errors?.selectedOption && (
                      <Form.Text className='text-danger'>{errors.selectedOption}</Form.Text>
                     )}
                  </Col>
                </Form.Group>
              </Col>
              <h5 className='mb-4 mt-4'> Terms and Conditions Details : </h5>
              <div className="d-flex flex-wrap">
                      <div className="col-6">
                        <p className="required">English Content</p>
                      </div>
                      <div className="col-6">
                        <p className="required ms-3">Hindi Content</p>
                      </div>
                    </div>
                {
                  termsAndConditions.map((field, index) => {
                  const {  content, contentHi } = field;
                    return (
                      <>
                        <div key={index} className="">
                          <Row>
                          
                            <Col sm={6}>
                              <Form.Group as={Row} controlId={`formGlobalDescription-${index}`}>
                                {/* <Form.Label className="required" column sm="4">
                                  English Content 
                                </Form.Label> */}
                                <Col sm="12">
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    style={{ height: 70 }}
                                    placeholder="English Content..."
                                    name="content"
                                    value={content}
                                    // onChange={(evnt) =>
                                    //   handlePackageDescriptionChange(index, 'content', evnt.target.value)
                                    // }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const updatedTerms = [...termsAndConditions];
                                      updatedTerms[index].content = value;
                                      setTermsAndConditions(updatedTerms);
                    
                                      // Clear error on change
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`content-${index}`]: '',
                                      }));
                                    }}
                                    isInvalid={!!errors[`content-${index}`]}
                  
                                  />
                                  <Form.Control.Feedback type="invalid">
                                       {errors[`content-${index}`]}
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Group>
                            </Col>
                            <Col sm={6}>
                              <Form.Group as={Row} controlId={`formGlobalDescriptionHi-${index}`}>
                                {/* <Form.Label className="required" column sm="4">
                                  Hindi Content 
                                </Form.Label> */}
                                <Col sm="12">
                                  <Form.Control
                                    type="text"
                                    as="textarea"
                                    style={{ height: 70 }}
                                    placeholder="Hindi Content..."
                                    name="contentHi"
                                    value={contentHi}
                                    // onChange={(evnt) =>
                                    //   handlePackageDescriptionChange(index, 'contentHi', evnt.target.value)
                                    // }
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      const updatedTerms = [...termsAndConditions];
                                      updatedTerms[index].contentHi = value;
                                      setTermsAndConditions(updatedTerms);
                    
                                      // Clear error on change
                                      setErrors((prevErrors) => ({
                                        ...prevErrors,
                                        [`contentHi-${index}`]: '',
                                      }));
                                    }}
                                    isInvalid={!!errors[`contentHi-${index}`]}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                     {errors[`contentHi-${index}`]}
                                  </Form.Control.Feedback>
                                </Col>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            {termsAndConditions.length !== 1 ? (
                              <div
                                onClick={() => removeInputField(index)}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              >
                                <button className="Dt-btn mt-3 mb-3 add-btn1">
                                  Remove
                                  <FaMinus className="hd-cr fw-bold" title="Remove Section" />
                                </button>
                              </div>
                            ) : (
                              ""
                            )}
                          </Row>
                        </div>
                      </>
                    );
                  })
                }

             
                <div className='mt-3'
                  onClick={addInputField}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'end',
                    cursor: 'pointer',
                    color: 'green',
                  }}
                >
                  <div className='Et-btn add-btn1 btn-success1'>
                    Add
                    <FaPlus className='hd-cr' title='Add Section' />
                  </div>
                </div>
            </Row>

            <div className='text-center mb-2'>
              <Button type='submit' variant='success' className='as_btn'>
                {loading ? 'Loading...' : `${editDataId ? 'Edit' : 'Add'}`}
              </Button>
              {editDataId ? (
                ''
              ) : (
                <Button variant='primary' type='button' className='as_btn reset-btn' onClick={handleReset}>
                  Reset
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CouponMasterAddEdit;
