import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';
import APIServicenew from '../../utils/APIGeneralService';
import Cookies from 'universal-cookie';
import bimg from '../../../src/imgs/banner_background.png';
import { Row, Form, Col } from 'react-bootstrap';


export function PujaImgGalleyModel({ show, onHide, handleSetImageUrl, fieldKey ,setClearSel ,clearSel }) {
  const cookies = new Cookies();
  const token = cookies.get('accessToken');
  const headers = { Authorization: `Bearer ${token}` };
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);

  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [selectedItemP, setSelectedItemP] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [mandirDetailsImg ,setMandirDetailsImg] = useState('')
  const [pujaLabhImg ,setPujaLabhImg] = useState('')
  const [dropDownList, setDropDownList] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [blogBanner,setBlogBanner] = useState('')
  const [blogImage,setBlogImage] = useState('')
  const [festivalBanner,setFestivalBanner] = useState('')
  const [festivalImage,setFestivalImage] = useState('')
  const [chadhawaListImageEn,setChadhawaListImageEn] = useState('')
  const [chadhawaListImageHi,setChadhawaListImageHi] = useState('')
  const [templeListAppImageEn, setTempleListAppImageEn] = useState('')
  const [templeListAppImageHi, setTempleListAppImageHi] = useState('')
  const [templeListWebImageEn, setTempleListWebImageEn] = useState('')
  const [templeListWebImageHi, setTempleListWebImageHi] = useState('')
  const [templeListWebDetailImageEn, setTempleListWebDetailImageEn] = useState('')
  const [templeListWebDetailImageHi, setTempleListWebDetailImageHi] = useState('')
  const [templeListBannerImageEn,setTempleListBannerImageEn] = useState('')
  const [templeListBannerImageHi,setTempleListBannerImageHi] = useState('')
  const [appBannerImageBanner, setAppBannerImageBanner] = useState('')
  const [appBannerImageBannerHindi, setAppBannerImageBannerHindi] = useState('')
  const [webBannerImageBanner, setWebBannerImageBanner] = useState('')
  const [webMobileViewImage, setWebMobileViewImage] = useState('')
  const [webBannerImageBannerHi, setWebBannerImageBannerHi] = useState('')
  const [webMobileViewImageHi, setWebMobileViewImageHi] = useState('')
  const [reportsImageEn, setReportsImageEn] = useState('')
  const [reportsImageHi, setReportsImageHi] = useState('')
  const [reelImage , setReelImage]  = useState('')
  const [mReelImg , setMReelImg]  = useState('')


  useEffect(() => {
    fetchData();

    if (clearSel === true) {
      setSelectedItemP('')
      setSelectedItems([])
      setMandirDetailsImg('')
      setPujaLabhImg('')
      setBlogImage('')
      setBlogBanner('')
      setFestivalBanner('')
      setFestivalImage('')
      setChadhawaListImageEn('')
      setChadhawaListImageHi('')
      setTempleListAppImageEn('')
      setTempleListWebImageEn('')
      setTempleListWebDetailImageEn('')
      setTempleListBannerImageEn('')
      setTempleListAppImageHi('')
      setTempleListWebImageHi('')
      setTempleListWebDetailImageHi('')
      setTempleListBannerImageHi('')
      setAppBannerImageBanner('')
      setAppBannerImageBannerHindi('')
      setWebBannerImageBanner('')
      setWebMobileViewImage('')
      setWebBannerImageBannerHi('')
      setWebMobileViewImageHi('')
      setReportsImageEn('')
      setReportsImageHi('')
      setReelImage('')
      setMReelImg('')
    }
  }, [clearSel,selectedValue]);
  useEffect(() => {
    getGalleryFolder()
  }, []);


  const getGalleryFolder=async()=>{
    let response = await APIServices.get(`mediaGallery/getGalleryFolder`, headers);

    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setDropDownList(apiData?.data)
      }
    }
  }

  async function fetchData() {
    setLoading(true);
    let response = await APIServices.get(`mediaGallery/getAll?folderName=${selectedValue}`, headers);
    let data = response?.response?.data;
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata);
        setLoading(false);
      }
    } else {
      if (data.status == 4) {
        //  logout()
      }
    }
  }

  const handleCardClick = (fileUrl, fieldKey) => {
    if (fieldKey === 'posterImage') {
      setSelectedItemP(fileUrl);
    }
    
    if (fieldKey === 'bannerImage') {
      setBlogBanner(fileUrl);
    }
    if (fieldKey === 'blogImage') {
      setBlogImage(fileUrl);
      
    }
    if (fieldKey === 'bannerImageFestival') {
      setFestivalBanner(fileUrl);
    }
    if (fieldKey === 'blogImageFestival') {
      setFestivalImage(fileUrl);
    }

    if (fieldKey === 'multiImg') {
      setSelectedItems(prevSelectedItems => {
        const isAlreadySelected = prevSelectedItems.includes(fileUrl);
        if (isAlreadySelected) {
          return prevSelectedItems.filter(item => item !== fileUrl);
        } else {
          return [...prevSelectedItems, fileUrl];
        }
      });
    }
    if (fieldKey === "mandirDetails"){
      setMandirDetailsImg(fileUrl)
    }
    if (fieldKey === "pujaLabhImg"){
      setPujaLabhImg(fileUrl)
    }
    if (fieldKey === 'ChadhawaListImageEn') {
      setChadhawaListImageEn(fileUrl);
    }
    if (fieldKey === 'ChadhawaListImageHi') {
      setChadhawaListImageHi(fileUrl);
    }
    if (fieldKey === 'appImageEn') {
      setTempleListAppImageEn(fileUrl);
    }
    if (fieldKey === 'appImageHi') {
      setTempleListAppImageHi(fileUrl);
    }
    if (fieldKey === 'webListImageEn') {
      setTempleListWebImageEn(fileUrl);
    }
    if (fieldKey === 'webListImageHi') {
      setTempleListWebImageHi(fileUrl);
    }
    if (fieldKey === 'webDetailImageEn') {
      setTempleListWebDetailImageEn(fileUrl);
    }
    if (fieldKey === 'webDetailImageHi') {
      setTempleListWebDetailImageHi(fileUrl);
    }
    if (fieldKey === 'bannerImageEn') {
      setTempleListBannerImageEn(fileUrl);
    }
    if (fieldKey === 'bannerImageHi') {
      setTempleListBannerImageHi(fileUrl);
    }
    if (fieldKey === 'AppBannerImageBanner') {
      setAppBannerImageBanner(fileUrl);
    }
    if (fieldKey === 'AppBannerImageBannerHindi') {
      setAppBannerImageBannerHindi(fileUrl);
    }
    if (fieldKey === 'WebBannerImageBanner') {
        setWebBannerImageBanner(fileUrl);
    }
    if (fieldKey === 'WebMobileViewImage') {
      setWebMobileViewImage(fileUrl);
    }
    if (fieldKey === 'WebBannerImageBannerHindi') {
      setWebBannerImageBannerHi(fileUrl);
    }
    if (fieldKey === 'WebMobileViewImageHindi') {
      setWebMobileViewImageHi(fileUrl);
    }
    if (fieldKey === 'ManageReportImageEn') {
      setReportsImageEn(fileUrl);
    }
    if (fieldKey === 'ManageReportImageHi') {
      setReportsImageHi(fileUrl);
    }
    if (fieldKey === 'reelImage') {
      setReelImage(fileUrl);
    }
    if (fieldKey === 'mReelImg') {
      setMReelImg(fileUrl);
    }
  };

  const handleSelect = () => {
    if (fieldKey === 'posterImage') {
      handleSetImageUrl(selectedItemP, fieldKey);
    }
    if (fieldKey === 'bannerImage') {
      handleSetImageUrl(blogBanner, fieldKey);
    }
    if (fieldKey === 'blogImage') {
      handleSetImageUrl(blogImage, fieldKey);
    }
    if (fieldKey === 'bannerImageFestival') {
      handleSetImageUrl(festivalBanner, fieldKey);
    }
    if (fieldKey === 'blogImageFestival') {
      handleSetImageUrl(festivalImage, fieldKey);
    }
    if (fieldKey === 'multiImg') {
      handleSetImageUrl(selectedItems, fieldKey);
    }
    if (fieldKey === "mandirDetails"){
      handleSetImageUrl(mandirDetailsImg, fieldKey);
    }
    if (fieldKey === "pujaLabhImg"){
      handleSetImageUrl(pujaLabhImg, fieldKey);
    }
    if (fieldKey === "ChadhawaListImageEn"){
      handleSetImageUrl(chadhawaListImageEn, fieldKey);
    }
    if (fieldKey === "ChadhawaListImageHi"){
      handleSetImageUrl(chadhawaListImageHi, fieldKey);
    }
    if (fieldKey === "appImageEn"){
      handleSetImageUrl(templeListAppImageEn, fieldKey);
    }
    if (fieldKey === "appImageHi"){
      handleSetImageUrl(templeListAppImageHi, fieldKey);
    }
    if (fieldKey === "webListImageEn"){
      handleSetImageUrl(templeListWebImageEn, fieldKey);
    }
    if (fieldKey === "webListImageHi"){
      handleSetImageUrl(templeListWebImageHi, fieldKey);
    }
    if (fieldKey === "webDetailImageEn"){
      handleSetImageUrl(templeListWebDetailImageEn, fieldKey);
    }
    if (fieldKey === "webDetailImageHi"){
      handleSetImageUrl(templeListWebDetailImageHi, fieldKey);
    }
    if (fieldKey === "bannerImageEn"){
      handleSetImageUrl(templeListBannerImageEn, fieldKey);
    }
    if (fieldKey === "bannerImageHi"){
      handleSetImageUrl(templeListBannerImageHi, fieldKey);
    }
    if (fieldKey === "AppBannerImageBanner"){
      handleSetImageUrl(appBannerImageBanner, fieldKey);
    }
    if (fieldKey === "WebBannerImageBanner"){
      handleSetImageUrl(webBannerImageBanner, fieldKey);
    }
    if (fieldKey === "WebMobileViewImage"){
      handleSetImageUrl(webMobileViewImage, fieldKey);
    }
    if (fieldKey === "AppBannerImageBannerHindi"){
      handleSetImageUrl(appBannerImageBannerHindi, fieldKey);
    }
    if (fieldKey === "WebBannerImageBannerHindi"){
      handleSetImageUrl(webBannerImageBannerHi, fieldKey);
    }
    if (fieldKey === "WebMobileViewImageHindi"){
      handleSetImageUrl(webMobileViewImageHi, fieldKey);
    }

    if (fieldKey === "ManageReportImageEn"){
      handleSetImageUrl(reportsImageEn, fieldKey);
    }

    if (fieldKey === "ManageReportImageHi"){
      handleSetImageUrl(reportsImageHi, fieldKey);
    }
    if (fieldKey === "reelImage"){
      handleSetImageUrl(reelImage, fieldKey);
    }
    if (fieldKey === "mReelImg"){
      handleSetImageUrl(mReelImg, fieldKey);
    }
    onHide();
  };

  return (
    <Modal
      show={show}
      id='my-modal'
      onHide={onHide}
      size='lg'
      backdrop='static'
      dialogClassName='custom-modal-width add-puja-gallery whatsapp-modal'
      scrollable={true}
    >
      <Modal.Header closeButton>
        <Modal.Title> {fieldKey === 'appImageEn' || fieldKey === 'appImageHi' ||  fieldKey === 'webListImageEn' || fieldKey === 'webListImageHi' || fieldKey === 'webDetailImageEn' || fieldKey === 'webDetailImageHi' || fieldKey === 'bannerImageEn' || fieldKey === 'bannerImageHi' 
        || fieldKey === 'ChadhawaListImageHi' || fieldKey === 'ChadhawaListImageEn' || fieldKey === 'bannerImage' ||
             fieldKey === 'blogImage' || fieldKey === 'reelImage' || fieldKey === 'mReelImg' ||
             fieldKey === 'bannerImageFestival' || fieldKey === 'blogImageFestival' || fieldKey === 'AppBannerImageBanner'  || fieldKey === 'WebBannerImageBanner' || fieldKey === 'WebMobileViewImage' || fieldKey === 'AppBannerImageBannerHindi'
             || fieldKey === 'WebBannerImageBannerHindi' || fieldKey === 'WebMobileViewImageHindi' 
             || fieldKey === 'ManageReportImageEn' || fieldKey === 'ManageReportImageHi'  ? "Image Gallery" : "Puja Image Gallery"}</Modal.Title>
        <div className='d-flex'>
          <Form.Group as={Row} className='ms-5' controlId='formTitleInput'>
                      <Form.Select
                        aria-label='label-selected-menu'
                        className='fw-bold label-selected-menu text-capitalize  w-100 hd-cr mt-2 form-ch py-4'
                        value={selectedValue}
                        onChange={(e)=> setSelectedValue(e.target.value)}
                        name='folderName'
                      >
                        <option value=''>Filter by folder</option>
                        {dropDownList && dropDownList?.length>0 && dropDownList.map((item)=>{
                          return <option value={item}>{item}</option>
                        })}
                      </Form.Select>
          </Form.Group>
         
              <Button className='as_btn' style={{marginLeft:"30px"}} onClick={handleSelect}>
               Select {fieldKey === 'posterImage' || fieldKey === 'mandirDetails' || fieldKey === 'ChadhawaListImageEn' 
               || fieldKey === 'ChadhawaListImageHi' || fieldKey === 'appImageEn' || fieldKey === 'appImageHi' || fieldKey === 'webListImageEn' || fieldKey === 'webListImageHi' || 
               fieldKey === 'webDetailImageEn' || fieldKey === 'webDetailImageHi' || fieldKey === 'bannerImageEn' || fieldKey === 'bannerImageHi'
             || fieldKey === 'bannerImage' ||
             fieldKey === 'blogImage' || fieldKey === 'reelImage' || fieldKey === 'mReelImg' ||
             fieldKey === 'bannerImageFestival' || fieldKey === 'blogImageFestival' || fieldKey === 'AppBannerImageBanner'  || fieldKey === 'WebBannerImageBanner' || fieldKey === 'WebMobileViewImage' || fieldKey === 'AppBannerImageBannerHindi' 
             || fieldKey === 'WebBannerImageBannerHindi' || fieldKey === 'WebMobileViewImageHindi' 
             || fieldKey === 'ManageReportImageEn' || fieldKey === 'ManageReportImageHi'  ? "Image" : "Images"} 
           

          </Button>

        </div>
      </Modal.Header>
      <Modal.Body>
        <div className='main aarti_section'>
          <div className='aarti_inner pt-4'>
            <div className='container'>
              <div className='row'>
                <div className='col-md-12 col-lg-12'>
                  {loading ? (
                    <div className='center'>
                      <Spinner animation='border' />
                    </div>
                  ) : (
                    <div className='row'>
                      {allData?.map(item =>
                        item?.file ? (
                          <div className='col-md-2 col-lg-3 col-xl-3' key={item?._id}>
                            <div
                              className={`card mb-3 Reels_video add-puja-modal custom-card hd-cr ${
                                selectedItemP.includes(item?.file)
                                || festivalBanner.includes(item?.file)
                                || festivalImage.includes(item?.file)
                                || blogBanner.includes(item?.file)
                                || blogImage.includes(item?.file)
                                ||selectedItems.includes(item?.file)
                                ||pujaLabhImg.includes(item?.file) 
                                ||mandirDetailsImg.includes(item?.file)
                                || chadhawaListImageEn.includes(item?.file)  
                                || chadhawaListImageHi.includes(item?.file)
                                || templeListAppImageEn.includes(item?.file)
                                || templeListWebImageEn.includes(item?.file)
                                || templeListWebDetailImageEn.includes(item?.file)
                                || templeListBannerImageEn.includes(item?.file) 
                                || templeListAppImageHi.includes(item?.file)
                                || templeListWebImageHi.includes(item?.file)
                                || templeListWebDetailImageHi.includes(item?.file)
                                || templeListBannerImageHi.includes(item?.file) 
                                || appBannerImageBanner.includes(item?.file)
                                || webBannerImageBanner.includes(item?.file)
                                || webMobileViewImage.includes(item?.file)
                                || appBannerImageBannerHindi.includes(item?.file)
                                || webBannerImageBannerHi.includes(item?.file)
                                || webMobileViewImageHi.includes(item?.file)
                                || reportsImageEn.includes(item?.file)
                                || reportsImageHi.includes(item?.file)
                                || reelImage.includes(item?.file)
                                || mReelImg.includes(item?.file)

                                ? 'imgselected' : ''
                              }`}
                              onClick={() => handleCardClick(item?.file, fieldKey)}
                            >
                              <div className='card-header'>
                                <img src={item?.file ? item?.file : bimg} className='card-img-top' alt='img' />
                              </div>
                                <p className='card-file-name font_14'>.. {item?.file.slice(-25)}</p>
                            </div>
                          </div>
                        ) : (
                          ''
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

