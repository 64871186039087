import { useEffect, useState, useContext } from "react";
import { Button, Modal, Row, Form, Col, Container } from "react-bootstrap";
import { UserContext } from "../context/UserContextProvider";

import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate'
import Spinner from 'react-bootstrap/Spinner';
import { FaEdit, FaTrash } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Cookies from 'universal-cookie';
import bimg from "../../src/imgs/banner_background.png";
import ModalComponent from "../components/Shared/ModalComponent";
import Sidebar from "../components/Shared/Sidebar";
import APIServicenew from "../utils/APIGeneralService";
import { getBaseURL, setCookies, SlugifyStringWithDash } from "../utils/Helper";
import { Heart, HeartFill } from 'react-bootstrap-icons';
import moment from "moment/moment";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

import usePermissionData from "../components/Customhook/custompermission";
import Notauthorized from "../components/Notauthorized";

export default function Reels() {
  document.title = 'Reels';
  const cookies = new Cookies();
  const token = cookies.get("accessToken");
  const { refreshToken } = useContext(UserContext);
  const permissioncontext = usePermissionData("reels")
  const APIServices = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const headers = { Authorization: `Bearer ${token}` };
  let astroUserId = cookies.get("astroUserId")
  const [offset, setOffset] = useState(0);
  const [perPage] = useState(8);
  const [pageCount, setPageCount] = useState(0)
  const [len, setLen] = useState()
  const [loading, setLoading] = useState()
  const [show, setShow] = useState(false);
  const [showM, setShowM] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showURLModal, setShowURLModal] = useState(false);
  const [showdt, setShowDt] = useState(false)
  const [type, setType] = useState("")
  const [allData, setAllData] = useState([])
  const [allDataCount, setAllDataCount] = useState([])
  const [data, setData] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false)
  const initialData = {
    title: "",
    description: "",
    titleHi: "",
    descriptionHi: "",
    slug: "",
    reelImage: "",
    video: "",
    tags: "",
    category: "",
    hash_tags: "",
    urls: "",
    metaTitle: "",
    metaDescription: "",
    metaKeyWords: "",
    metaTitleHi: "",
    metaDescriptionHi: "",
    metaKeyWordsHi: "",
    multiImgData: []
  }
  const [reelImg, setReelImg] = useState("");
  const [contentType, setContentType] = useState('');
  const [newData, setNewData] = useState(initialData);
  const [progress, setProgress] = useState(0)
  const [selectedIds, setSelectedIds] = useState([])
  const [urlData, setURLData] = useState();
  const navigate = useNavigate();
  const [flag, setFlag] = useState();
  const [flag1, setFlag1] = useState(false)
  const [selectedId, setSelectedId] = useState('');
  const [name, setName] = useState('');


  const logout = () => {
    refreshToken(null)
    setCookies("accessToken", null);
    navigate("/");
  }
  useEffect(() => {
    fetchData();
  }, [offset]);

  async function fetchData() {
    setLoading(true)
    let response = await APIServices.get(`astroReel/getAllReel/${offset}/${perPage}/?startDate=${startDate}&endDate=${endDate}&type=${type}&title=${name}`, headers)
    let data = response?.response?.data
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status) {
        setAllData(apiData.Listdata)
        setAllDataCount(apiData.totalCount)
        setLen(apiData.totalCount);
        setPageCount(Math.ceil(apiData.totalCount / perPage));
        setFlag1(false)
        setLoading(false)
      }
    } else {
      if (data?.status == 4) {
        logout()
      }
    }
  }
  const handleFilterClick = () => {

    if (startDate || endDate || offset || type ||name) {
      fetchData()
    }
  }

  const handleStartDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setStartDate(formattedDate);
  };

  const handleEndDate = (date) => {
    const formattedDate = moment(date).format("MM-DD-YYYY");
    setEndDate(formattedDate);
  };

  const resetData = () => {
    setStartDate("")
    setEndDate("")
    setOffset(0)
    setType("")
    setName("")
    setFlag1(true)
  }

  useEffect(() => {

    if (reelImg) {
      setNewData(prev => ({ ...prev, ["reelImage"]: reelImg }))
      if (!!errors["reelImage"]) {
        setErrors({
          ...errors,
          ["reelImage"]: null,
        });
      }
    }
    fetchData()
  }, [flag1, reelImg])


  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const newOffset = (selectedPage * perPage) % len;
    setOffset(newOffset)
  }

  async function updateFile() {
    let res = await APIServices.get(`astroReel/updateFile`, headers)
    if (res?.status === 200) {
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData();
      }
    }
  }
  const validateInputs = () => {
    const newErrors = {};

    if (contentType === "video") {
      if (!newData.urls) {
        if (newData.video === "")
          newErrors.video = "Please upload video.";
      }
      if (!newData.video) {
        if (newData.urls === "")
          newErrors.urls = "Please add urls.";
      }
    } else {

      if (newData.reelImage === "") {
        newErrors.reelImage = "Please select image.";
      }

    }

    // new
    if (newData.title === "") {
      newErrors.title = "Please enter title.";
    }

    if (newData.description === "") {
      newErrors.description = "Please enter description.";
    }
    if (newData.titleHi === "") {
      newErrors.titleHi = "Please enter titleHi.";
    }
    if (newData.descriptionHi === "") {
      newErrors.descriptionHi = "Please enter descriptionHi.";
    }
    if (newData.slug === "") {
      newErrors.slug = "Please enter slug.";
    }

    if (newData.metaTitle === "") {
      newErrors.metaTitle = "Please enter metaTitle.";
    }
    if (newData.metaTitleHi === "") {
      newErrors.metaTitleHi = "Please enter metaTitleHi.";
    }
    if (newData.metaDescription === "") {
      newErrors.metaDescription = "Please enter metaDescription.";
    }
    if (newData.metaDescriptionHi === "") {
      newErrors.metaDescriptionHi = "Please enter metaDescriptionHi.";
    }
    if (newData.metaKeyWords === "") {
      newErrors.metaKeyWords = "Please enter metaKeyWords.";
    }
    if (newData.metaKeyWordsHi === "") {
      newErrors.metaKeyWordsHi = "Please enter metaKeyWordsHi.";
    }

    return newErrors;
  };

  async function addReel() {
    let payload = new FormData();

    if (contentType === "video") {
      payload.append('video', newData?.video);
    } else {
      payload.append('reelImage', newData?.reelImage);
    }
    // payload.append('tags', newData?.tags);
    // payload.append('category', newData?.category);
    // payload.append('hash_tags', newData?.hash_tags);
    payload.append('title', newData.title);
    payload.append('description', newData.description);
    payload.append('titleHi', newData.titleHi);
    payload.append('descriptionHi', newData.descriptionHi);
    payload.append('slug', newData.slug);
    payload.append('type', contentType);
    payload.append('metaTitle', newData.metaTitle);
    payload.append('metaTitleHi', newData.metaTitleHi);
    payload.append('metaDescription', newData.metaDescription);
    payload.append('metaDescriptionHi', newData.metaDescriptionHi);
    payload.append('metaKeyWords', newData.metaKeyWords);
    payload.append('metaKeyWordsHi', newData.metaKeyWordsHi);
    // payload.append('multiImgData', JSON.stringify(inputFields));


    payload.append("userId", astroUserId)

    const newErrors = validateInputs();
    setLoader(true)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      setSelectedFile(newData?.video);

      let response = await APIServices.post(`astroReel/addReel`, payload, { ...headers, "Content-Type": "multipart/form-data" }, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted)
      })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false)
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }

  async function addReelURL() {
    let payload = {
      'category': newData?.category,
      'urls': newData?.urls,
      'astroUserId': astroUserId,
    }

    // payload.append('category', newData?.category);
    // payload.append('urls', newData?.urls);
    // payload.append("userId", astroUserId)
    const newErrors = validateInputs();
    setLoader(true)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.post(`astroReel/addReelURL`, payload, { ...headers, "Content-Type": "application/json" })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowURLModal(false);

          toast.success(apiData?.message);
          setLoader(false)
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setLoader(false)

        }
      }
      setLoader(false)
    }
  }

  async function updateReel() {
    let payload = new FormData();


    // payload.append('tags', newData?.tags);
    // payload.append('category', newData?.category);
    // payload.append('hash_tags', newData?.hash_tags);
    payload.append('description', newData.description);
    payload.append('title', newData.title);
    payload.append('titleHi', newData.titleHi);
    payload.append('descriptionHi', newData.descriptionHi);
    payload.append('slug', newData.slug);
    payload.append('metaTitle', newData.metaTitle);
    payload.append('metaTitleHi', newData.metaTitleHi);
    payload.append('metaDescription', newData.metaDescription);
    payload.append('metaDescriptionHi', newData.metaDescriptionHi);
    payload.append('metaKeyWords', newData.metaKeyWords);
    payload.append('metaKeyWordsHi', newData.metaKeyWordsHi);
    // payload.append('multiImgData', JSON.stringify(inputFields));



    payload.append("userId", astroUserId);

    // payload.append("video", newData?.video);
    if (newData?.reelImage) {
      payload.append('reelImage', newData?.reelImage);
      payload.append('type', "image");

    } 
    if(newData?.video) {
      payload.append('video', newData?.video);
    }
    // if (newData?.video == '') {
    //   payload.append('source', newData?.source);
    // }
    setSelectedFile(newData?.video);
    setLoader(true)
    const newErrors = validateInputs();
    setLoader(true)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setLoader(false)
    } else {
      let response = await APIServices.put(`astroReel/updateReel/${newData?._id}`, payload, { ...headers, "Content-Type": "multipart/form-data" }, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted)
      })
      if (response?.status === 200) {
        let apiData = response?.data;
        if (apiData?.status === true) {
          setShowModal(false);
          toast.success(apiData?.message);
          setLoader(false)
          fetchData();
        } else if (apiData?.status === false) {
          toast.error(apiData?.message);
          setShowModal(true)
          setLoader(false)
        }
      }
      setLoader(false)
    }
  }



  const addMultiReel = async () => {
    let payload = new FormData();

    // payload.append('multiImgData', JSON.stringify(inputFields));
    const sanitizedInputFields = inputFields.map(({ preview_url, ...rest }) => rest);
    payload.append('multiImgData', JSON.stringify(sanitizedInputFields));

    let response = await APIServices.post(`astroReel/addMultiReel/${selectedId}`, payload, headers)
    if (response?.status === 200) {
      let apiData = response?.data;
      if (apiData?.status === true) {
        setShowM(false);
        setSelectedId('')
        toast.success(apiData?.message);
        fetchData();
      } else if (apiData?.status === false) {
        toast.error(apiData?.message);
        setShowM(true)
      }
    }
  }
  const handleCloseDt = () => setShowDt(false)
  const handleShowDt = () => {
    setShowDt(true)
  }
  const multidelete = () => {
    if (flag === true) {
      setShowDt(true)
    } else {
      toast.warn("Please select cards for delete");
    }
  }

  const handleMultiDt = (e, id) => {
    let checked = e.target.checked
    if (checked === true) {
      setFlag(true)
      setSelectedIds(current => [...current, id]);
    } else if (checked === false) {
      setSelectedIds(current => current.filter((ls) => ls !== id))
    }
  }

  useEffect(() => {
    if (selectedIds?.length === 0) {
      setFlag(false)
    }
  }, [selectedIds])

  const handleMultidelete = async () => {

    let payload = { reelIds: selectedIds }
    let res = await APIServices.delete(`astroReel/deleteMultiReels`, headers, payload)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        setSelectedIds([])
        setFlag(false)
        fetchData()
      }
    }
  }

  const handledelete = async () => {
    let res = await APIServices.delete(`astroReel/deleteReel/${newData?._id}`, headers)
    if (res?.status === 200) {
      setShowDt(false)
      setOffset(0)
      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
      }
    }
  }
  const multiActivecheck = () => {
    if (flag === true) {
      multiActive()
    } else {
      toast.warn("Please select records for Active");
    }
  }

  const multiActive = async () => {
    let payload = { reelIds: selectedIds, status: true }

    let res = await APIServices.put(`astroReel/updateMulitStatus`, payload, headers)
    if (res?.status === 200) {



      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
        setSelectedIds([])
        setFlag(false)

      }
    }
  }


  const multiInactivecheck = () => {
    if (flag === true) {
      multiInactive()
    } else {
      toast.warn("Please select records for Inactive");
    }
  }


  const multiInactive = async () => {
    let payload = { reelIds: selectedIds, status: false }

    let res = await APIServices.put(`astroReel/updateMulitStatus`, payload, headers)
    if (res?.status === 200) {



      let apiData = res?.data;
      if (apiData?.status) {
        toast.success(apiData?.message);
        fetchData()
        setSelectedIds([])
        setFlag(false)

      }
    }
  }

  const handleclick = async (status, id) => {
    var datas = status === true ? false : true;
    const formData = new FormData();
    formData.append("status", datas);
    await APIServices.put(`astroReel/updateStatus/${id}`, formData, headers)
      .then((res) => {
        fetchData()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function handleChange(e, field = "text") {
    let key, value;
    key = e?.target?.name;
    if (field === "text" || field === "slug") {
      value = e?.target?.value;
    }
    else if (field === "file") {
      value = e?.target?.files?.[0];
    } else if (field === "audio") {
      value = e?.target?.files?.[0];
    } else if (field === "video") {
      value = e?.target?.files?.[0];
    }
    if (!!errors[key]) {
      setErrors({
        ...errors,
        [key]: null,
      });
    }
    setNewData(prev => ({ ...prev, [key]: value }))
  }
  function handleRt(e) {
    let key = e?.target?.name;
    if (!!errors[key])
      setErrors({
        ...errors,
        [key]: null,
      });
  }

  const [inputFields, setInputFields] = useState([
    {
      title: "",
      description: "",
      titleHi: "",
      descriptionHi: "",
      MReelImg: "",
    },
  ]);

  const handleImage = (index, evnt, field) => {
    let key, value;
    key = evnt?.target?.name
    const list = [...inputFields];
    if (field === "file") {
      value = evnt;
      list[index]["preview_url"] = URL.createObjectURL(evnt);
    }
    list[index]["MReelImg"] = value;
    setInputFields(list);
  };

  return (
    <>
      {
        permissioncontext.view ?
          <div className="main aarti_section">
            <div className="gita_quote_heading">
              <div className="container d-flex align-items-center justify-content-between">
                <h4 className="hd-list-f left-q" >Stories <small>({allDataCount})</small></h4>
                <div className="_btn">

                  <Button variant='primary' disabled={!permissioncontext.edit} className='by-btn as_btn' onClick={() => { multiActivecheck() }}>
                    Multi Active
                  </Button>
                  <Button variant='primary' disabled={!permissioncontext.edit} className='by-btn as_btn' onClick={() => { multiInactivecheck() }}>
                    Multi Inactive
                  </Button>
                  <Button
                    style={{ backgroundColor: 'green' }}
                    className="by-btn as_btn me-0"
                    variant="primary"
                    disabled={!permissioncontext.add}
                    onClick={() => {
                      // if(canAdd) {
                      setNewData(initialData);
                      setShowModal(true);
                      // }
                    }}
                  >
                    Add Stories
                  </Button>&nbsp;&nbsp;

                  {/* <Button
                    className="by-btn as_btn me-0"
                    variant="primary"
                    disabled={!permissioncontext.add}
                    onClick={() => {
                      // if(canAdd) {
                      setNewData(initialData);
                      setShowURLModal(true);
                      // }
                    }}
                  >
                    Add Stories URL
                  </Button>&nbsp;&nbsp; */}
                  <Button
                    className="by-btn as_btn me-0"
                    style={{ background: "red" }}
                    variant="primary"
                    disabled={!permissioncontext.delete}
                    onClick={() => {
                      // if(candelete)
                      multidelete()
                    }}
                  >
                    Multi Delete
                  </Button>
                </div>
              </div>
            </div>
            <div className="aarti_inner">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-lg-12">
                    {loading ? <div className="center"><Spinner animation="border" /></div> :
                      <>
                        <div className="chat_window">
                          <Row className="mb-4 mlist-1">
                            <div class="feed_area">
                              <div class="chat_search_box">
                                <div className="chat-search-field">
                                  <Row>
                                    <Form>
                                      <Row>
                                        <Form.Group as={Row} className="mb-3 align-items-center" controlId="">
                                          <Form.Label class="label-f-w-1  mt-2 ">Search Date By</Form.Label>
                                          <Col md={2}>
                                            {/* <Form.Control
                    className="hd-cr mt-2 form-ch"
                    name="date"
                    type="date"
                    placeholder="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  /> */}
                                            <DatePicker
                                              placeholderText={"Start date"}
                                              className="form-control form-ch fil-date-pick mt-2"
                                              name="date"
                                              selected={startDate ? new Date(startDate) : null}
                                              onChange={(date) => {
                                                handleStartDate(date);
                                              }}
                                              dateFormat="yyyy-MM-dd"
                                            />
                                          </Col>
                                          <Col md={2}>
                                            {/* <Form.Control
                   className="hd-cr mt-2 form-ch"
                   name="date"
                   type="date"
                   placeholder="date"
                   value={endDate}
                    onChange={(e) => setEndDate( e.target.value)}
                 /> */}
                                            <DatePicker
                                              placeholderText={"End date"}
                                              className="form-control form-ch fil-date-pick mt-2"
                                              name="date"
                                              selected={endDate ? new Date(endDate) : null}
                                              onChange={(date) => {
                                                handleEndDate(date);
                                              }}
                                              dateFormat="yyyy-MM-dd"
                                            />
                                          </Col>
                                           <Col lg={3}>
            
                                          <Form.Control
                                            className=' mt-2 form-ch'
                                            name='title'
                                            type='search'
                                            placeholder='Search By Title'
                                            value={name}
                                            onChange={e => {
                                              setName(e.target.value);
                                              setOffset(0);
                                            }}
                                          />
                                        </Col>


                                          <Col md={2} className="mt-2">
                                          <Form.Select
                                            aria-label='categories'
                                            className=' w-100 astroWatch-select-hover hd-cr'
                                            value={type}
                                            onChange={e => {
                                              setType(e.target.value);
                                              setOffset(0);
                                            }}
                                          >
                                            <option value='all'>Type</option>
                                            <option value='image'>Image</option>
                                            <option value='video'>Video</option>
                                          </Form.Select>
                                          </Col>

                                          <Button variant="secondary"
                                            className="as_btn"
                                            onClick={() => { handleFilterClick(); setOffset(0) }}
                                          >
                                            Filter
                                          </Button>
                                          <Button variant="secondary"
                                            className="as_btn reset-btn"
                                            onClick={() => { resetData(); setOffset(0) }}
                                          >
                                            Reset
                                          </Button>
                                        </Form.Group>
                                      </Row>
                                    </Form>
                                  </Row>
                                </div>
                              </div>
                            </div>
                            <Container>
                              <div className=" row">
                                {
                                  allData && allData.length > 0 && allData?.map((item) =>
                                    <div className="col-md-6 col-lg-4 col-xl-3">
                                      <div className="card mb-3 Reels_video" key={item?._id} >
                                        <div className="card-header mb-2">

                                          {item?.reelImage ? <img
                                            name='image'
                                            style={{ width: '100%', height: '235px' }}
                                            alt='img'
                                            src={(item?.reelImage) ? item?.reelImage : bimg}
                                          /> :
                                            <video
                                              className="aarti_video"
                                              controls
                                              src={(item?.video) ? item?.video : bimg}
                                            />
                                          }
                                          <div className=" icon1">
                                            <input
                                              className="form-check-input border_checkbox_c"
                                              type="checkbox"
                                              name="languages"
                                              value="Javascript"
                                              id="flexCheckDefault"
                                              onChange={(e) => { handleMultiDt(e, item?._id) }

                                              }
                                            /></div>
                                          <div className="icon card-icons">
                                            <FaEdit
                                              className={permissioncontext.edit ? "me-2 hd-cr" : "me-2 hd-cr disabled-btn"}
                                              size={20}
                                              onClick={() => {
                                                if (permissioncontext.edit) {
                                                  setShowModal(true);
                                                  setNewData({
                                                    _id: item._id,
                                                    // category: item.category ?? "",
                                                    // hash_tags:item?.hash_tags ?? "",
                                                    // tags:item?.tags ?? "",
                                                    video: item.video ?? "",
                                                    title: item.title ?? "",
                                                    description: item?.description ?? "",
                                                    titleHi: item?.titleHi ?? "",
                                                    descriptionHi: item?.descriptionHi ?? "",
                                                    slug: item?.slug ?? "",
                                                    metaTitle: item?.metaTitle ?? "",
                                                    metaDescription: item?.metaDescription ?? "",
                                                    metaKeyWords: item?.metaKeyWords ?? "",
                                                    metaTitleHi: item?.metaTitleHi ?? "",
                                                    metaDescriptionHi: item?.metaDescriptionHi ?? "",
                                                    metaKeyWordsHi: item?.metaKeyWordsHi ?? "",
                                                    multiImgData: item?.multiImgData ?? "",
                                                    reelImage: item?.reelImage ?? ""
                                                  });

                                                }
                                              }}
                                            />

                                            <FaTrash
                                              className={permissioncontext.delete ? "dt-btn hd-cr" : "me-2 hd-cr disabled-btn"}
                                              size={20}
                                              onClick={e => {
                                                if (permissioncontext.delete) {
                                                  setNewData(item);
                                                  handleShowDt()
                                                  setFlag(false)
                                                }
                                              }}
                                            />
                                            {item.status === true ? (
                                              <input
                                                type="button"
                                                disabled={!permissioncontext.edit}
                                                className={!permissioncontext.edit ? "_active disabled-btn" : '_active '}
                                                style={{ fontSize: "8px", borderRadius: "15px" }}
                                                value="Active"
                                                onClick={() =>
                                                  handleclick(item.status, item._id)
                                                }
                                              />
                                            ) : (
                                              <input
                                                type="button"
                                                disabled={!permissioncontext.edit}
                                                className={!permissioncontext.edit ? "_inactive disabled-btn" : '_inactive '}
                                                style={{ fontSize: "8px", borderRadius: "15px" }}
                                                value="Inactive"
                                                onClick={() =>
                                                  handleclick(item.status, item._id)
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <div className="card-body height_box_reel " >
                                          <p style={{ textAlign: "center", fontSize: "15px" }} className="description font-500 mb-2">
                                            <span className="calicon">
                                              <i className="ri-calendar-line"></i>
                                            </span>
                                            {/* {new Date(item.createdAt).toDateString()} */}
                                            {moment(item.createdAt).format("DD MMM, YYYY hh:mm A")}
                                          </p>

                                          <h6 className="mb-2" style={{ color: "blue", fontSize: "13px" }}>{item.hash_tags}</h6>

                                          <h4 className="mb-2">{item.title}</h4>

                                          <p className="card-text-reel description">

                                            {item.description === true ? item.description?.slice(0, 180) : item.description?.slice(0, 180)}

                                          </p>
                                        </div>

                                        <div className={`card-footer d-flex flex-wrap align-items-center ${item?.type === "image" ? "justify-content-end" :"justify-content-between"}  px-2 bg-white`}>

                                        {item?.type === "image" ?"" :
                                          <div className="comment_part">
                                            <span className="cursor-pointer ms-2" style={{ fontSize: 14 }}>
                                              <span>
                                                {
                                                  item?.like_count > 0 ? (
                                                    <HeartFill style={{ color: '#B21414' }} />
                                                  ) : (
                                                    <Heart />
                                                  )} </span>{' '}
                                              Like {item?.like_count}
                                            </span>
                                            <span className="cursor-pointer">
                                              <button className="bg-transparent border-0" style={{ fontSize: 14 }}>

                                                <span className="mx-1" >Comments</span>
                                                ({item?.comment_count > 0 ? item?.comment_count : 0})
                                              </button>
                                            </span>
                                          </div> 
                                          }
                                          <Button variant="secondary"
                                            className="as_btn"
                                            disabled={!permissioncontext.edit}
                                            onClick={() => {
                                              setShowM(true);
                                              setInputFields(item?.multiImgData && Array.isArray(item.multiImgData)
                                                ? item.multiImgData
                                                : [{ title: "", description: "",  titleHi: "",
                                                  descriptionHi: "", MReelImg: "" }]
                                              );
                                              setSelectedId(item._id);
                                            }}
                                          >
                                            Add Slider
                                          </Button>
                                        </div>

                                      </div>
                                    </div>

                                  )
                                }

                              </div>
                            </Container>
                          </Row>
                        </div>

                      </>
                    }

                    {loading ? "" :
                      allData && allData.length > 0 ?
                        (
                      <div className="pagination">
                      
                            <ReactPaginate
                              breakLabel="..."
                              nextLabel="Next>"
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              previousLabel="< Previous"
                              renderOnZeroPageCount={null}
                              breakClassName={"page-item"}
                              breakLinkClassName={"page-link"}
                              containerClassName={"pagination"}
                              pageClassName={"page-item"}
                              pageLinkClassName={"page-link"}
                              previousClassName={"page-item"}
                              previousLinkClassName={"page-link"}
                              nextClassName={"page-item"}
                              nextLinkClassName={"page-link"}
                              activeClassName={"active"}
                              forcePage={offset / perPage}
                            />
                      </div>
                    ) : (<h2 className="no_records">Records not Available</h2>)
                  }
                  </div>
                </div>
              </div>
            </div>
          </div> :
          <Notauthorized />
      }
      <ModalComponent
        id="readMore"
        show={show}
        onHide={() => setShow(false)}
        modalHeading={newData?.title}
        size="lg"
        localData={newData.contentLangEn === true ? newData?.contentEn : newData?.contentHi}
      />

      <ModalComponent
        id="updateReel"
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setErrors("")
        }}
        modalHeading={(newData?._id) ? "Edit Story" : "Add Story"}
        size="xl"
        localData={newData}
        setReelImg={setReelImg}
        setContentType={setContentType}  // ✅ Passing function
        contentType={contentType}        // ✅ Passing state value
        handleImage={handleImage}
        errors={errors}
        progress={progress}
        handleRt={handleRt}
        onButtonClick={(newData?._id) ? updateReel : addReel}
        handleChange={handleChange}
        loader={loader}
      />

      <ModalComponent
        id="addReelURL"
        show={showURLModal}
        onHide={() => { setShowURLModal(false); setErrors("") }}
        modalHeading={"Add Stories URL's"}
        size="lg"
        localData={newData}
        errors={errors}
        handleRt={handleRt}
        onButtonClick={addReelURL}
        handleChange={handleChange}
        loader={loader}
      />


      <ModalComponent
        id="multiReelAdd"
        show={showM}
        onHide={() => {
          setShowM(false);
          setInputFields([{
            title: "",
            description: "",
            titleHi: "",
            descriptionHi: "",
            MReelImg: "",
          }]);
        }}
        modalHeading={"Add More Story Data"}
        size="lg"
        localData={newData}
        handleImage={handleImage}
        setInputFields={setInputFields}
        inputFields={inputFields}
        errors={errors}
        progress={progress}
        handleRt={handleRt}
        onButtonClick={addMultiReel}
        handleChange={handleChange}
        loader={loader}
      />


      <Modal show={showdt} size="sm" aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static" onHide={handleCloseDt}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flag === true ? `Are you sure you want to delete ${selectedIds?.length} records?` : ` Are you sure you want to delete this element?`}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="cancle_btn" onClick={handleCloseDt}>
            Cancel
          </Button>
          <Button variant="primary" className="success_btn" onClick={flag === true ? handleMultidelete : handledelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </>
  )
}