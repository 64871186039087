import { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { getBaseURL, SlugifyStringWithDash } from '../../utils/Helper';
import upimg from '../../imgs/default.jpg';
import CropModal from './CropModal';
import { PujaImgGalleyModel } from './pujaImgGalleryModal';

export default function ModalComponent({
  id,
  show = false,
  onHide,
  size = 'lg',
  modalHeading,
  localData,
  setReelImg,
  setContentType,
  contentType,
  inputFields,
  setInputFields,
  errors,
  setlocalData,
  handleChange,
  onButtonClick,
  handleImage,
  progress,
  handleRt,
  showcrop,
  setShowCrop,
  cropimg,
  setCropImg,
  caartifileimage,
  setFileCaartiimage,
  loader,
}) {

  const [source, setSource] = useState();
  const [imagePreview, setImagePreview] = useState();

  useEffect(() => {
    if (localData?.video != '') {
      setSource(localData?.video);
    } else if (localData?.video === '') {
      setSource('');
    }
    if (typeof setContentType === "function") {
      if (localData?.reelImage !== "" &&  typeof localData?.video === "string" ) {
        setContentType("image"); 
      } else if (localData?.reelImage === "" && localData?.video != '') {
        setContentType("video");
      }
      // } else {
      //   setContentType("image");
      // console.log("🚀 ~ useEffect ~  3333333333333333333333", )
      // }
    }

  }, [localData]);

  const inputRef = useRef();
  const footerButton = {
    buttonName: localData?._id ? 'Edit' : 'Add',
    buttonState: true,
  };
  const footerButtonloader = {
    buttonName: localData?._id
      ? progress === 100
        ? `Processing...`
        : `Uploading(${progress}%)...`
      : progress === 100
        ? `Processing...`
        : `Uploading(${progress}%)...`,
    buttonState: true,
  };

  let footerContent = () => {
    return (
      <>
        {loader
          ? footerButtonloader.buttonState && (
            <Button variant='success' className='as_btn'>
              {footerButtonloader.buttonName}
            </Button>
          )
          : footerButton.buttonState && (
            <Button variant='success' className='as_btn' onClick={() => onButtonClick(footerButton.buttonName)}>
              {footerButton.buttonName}
            </Button>
          )}
      </>
    );
  };

  const previewVideo = event => {
    const file = event.target.files[0];
    handleChange(event, 'video');
    const url = URL.createObjectURL(file);
    setSource(url);
  };
  const handleChoose = event => {
    inputRef.current.click();
  };

  // /////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (cropimg && caartifileimage) {
      handleImage(caartifileimage);
    }
  }, [cropimg, caartifileimage]);

  // image gallery
  const [modalFieldKey, setModalFieldKey] = useState(null);
  const [showImageGalleyModel, setShowImageGalleyModel] = useState(false);
  const [clearSel, setClearSel] = useState(false);
  const [rindex, setRindex] = useState();
  const handleOpenModal = (fieldKey, index) => {
    setClearSel(true);
    setModalFieldKey(fieldKey);
    setRindex(index);
    setShowImageGalleyModel(true);
  };

  const extractPaths = url => {
    const extractPath = singleUrl => {
      const parts = singleUrl.split('cloudfront.net/');
      if (parts.length > 1) {
        return parts[1];
      }
      return singleUrl;
    };

    if (Array.isArray(url)) {
      return url.map(singleUrl => extractPath(singleUrl));
    } else if (typeof url === 'string') {
      return extractPath(url);
    }
    return url;
  };

  const handleSetImageUrl = (urls, fieldKey) => {
    let extractedPath = extractPaths(urls);
    setClearSel(false);
    if (fieldKey === 'reelImage') {

      setImagePreview(urls);
      setReelImg(extractedPath);

      setShowImageGalleyModel(false);
    }
    if (fieldKey === 'mReelImg') {
      const updatedFields = inputFields.map((field, index) =>
        index === rindex ? { ...field, MReelImg: extractedPath, preview_url: urls } : field
      );
      setInputFields(updatedFields);
    }
  };

  // multi

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        title: '',
        content: '',
        MReelImg: '',
      },
    ]);
  };

  const removeInputFields = index => {
    let rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChangeRL = (index, evnt, field) => {
    let key, value;
    key = evnt?.target?.name;
    if (!!errors?.reelmultiImgData) {
      errors?.reelmultiImgData?.forEach((field, index) => {
        if (errors.reelmultiImgData[index][key] != null || errors.reelmultiImgData[index][key] != '') {
          errors.reelmultiImgData[index][key] = null;
        }
      });
    }
    const list = [...inputFields];
    if (field === 'text') {
      value = evnt?.target?.value;
    }
    // else if (field === "file") {
    //   value = evnt;
    //   list[index]["preview_url"] = URL.createObjectURL(evnt);
    // }
    list[index][key] = value;
    setInputFields(list);
  };

  // /////////////////////////////////////////////////////////////////

  function renderUpdateAarti() {
    return (
      <Form>
        <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
          <Form.Label column sm='3' className='required'>
            Title
          </Form.Label>
          <Col sm='9'>
            <Form.Control
              type='text'
              placeholder='Title...'
              name='title'
              isInvalid={!!errors?.title}
              value={localData?.title}
              onChange={e => handleChange(e, 'text')}
            />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
          <Form.Label column sm='3' className='required'>
            Content
          </Form.Label>
          <Col sm='9'>
            <Form.Control
              as='textarea'
              name='content'
              isInvalid={!!errors?.content}
              placeholder='Content...'
              value={localData?.content}
              style={{ height: 250 }}
              onChange={e => handleChange(e, 'text')}
            />
            <Form.Control.Feedback type='invalid'>{errors?.content}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
          <Form.Label column sm='3' className='required'>
            Image
          </Form.Label>
          <Col sm='9'>
            <label id='labimg'>
              {cropimg ? (
                <img
                  src={cropimg}
                  onClick={() => setCropImg()}
                  // crossOrigin="anonymous"
                  className='imgs'
                  alt=''
                  width='100%'
                />
              ) : (
                <img
                  // crossOrigin="anonymous"
                  className='imgs'
                  alt=''
                  width='100%'
                  src={localData?.file ? localData?.file : upimg}
                />
              )}
              {cropimg ? (
                <input onClick={e => setShowCrop(true)} />
              ) : (
                <>
                  <Form.Control
                    // type="file"
                    className='form-control '
                    isInvalid={!!errors?.file}
                    name='file'
                    // size="40"
                    onClick={e => {
                      setShowCrop(true);
                      handleRt(e);
                    }}
                    accept='image/png, image/gif, image/jpeg'
                    id='inputds'
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.file}</Form.Control.Feedback>
                </>
              )}
            </label>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
          <Form.Label className='required' column sm='3'>
            Content Type
          </Form.Label>
          <Col sm='9'>
            <Form.Select
              aria-label='Default select example'
              required
              className='hd-cr'
              isInvalid={!!errors?.imageType}
              name='imageType'
              value={localData?.imageType}
              onChange={e => handleChange(e, 'text')}
            >
              <option value=''>Choose</option>
              <option value='english'>English</option>
              <option value='hindi'>Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type='invalid'>{errors?.imageType}</Form.Control.Feedback>
          </Col>
          {/* <Col sm="4"></Col> */}
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
          <Form.Label column sm='3' className='required'>
            Audio File
          </Form.Label>
          <Col sm='9'>
            <Form.Control
              type='file'
              name='audio'
              isInvalid={!!errors?.audio}
              onChange={e => handleChange(e, 'audio')}
              accept='audio/*'
              placeholder=''
              className='profile-aud'
            />
            <Form.Control.Feedback type='invalid'>{errors?.audio}</Form.Control.Feedback>
            {typeof localData?.audio === 'object' ? (
              ''
            ) : (
              <span className='ms-3' style={{ color: '' }}>
                <a rel='noopener' target='_blank' href={localData.audio}>
                  {localData.audio === 'https://d3i0p1mk3sd6q7.cloudfront.net/undefined'
                    ? ''
                    : localData.audio?.slice(26)}
                </a>
              </span>
            )}
          </Col>
        </Form.Group>
        {/* {(modalHeading === "Edit Advice" ||modalHeading === "Add Advice" ) ? "" :<>  
            <Form.Group as={Row} className="mb-3" controlId="formTitleInput">
          <Form.Label column sm="3" className="required">
            Song URL
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              placeholder="Song URL..."
              isInvalid={!!errors?.songUrl}
              name="songUrl"
              value={localData?.songUrl}
              onChange={(e) => handleChange(e, 'text')}
            />
             <Form.Control.Feedback type="invalid">
              {errors?.songUrl}
           </Form.Control.Feedback>
          </Col>
         
        </Form.Group>
              
             <Form.Group as={Row} className="mb-3" controlId="formAartiInput">
          <Form.Label column sm="3" className="required">
          Description
          </Form.Label>
          <Col sm="9">
            <Form.Control
              type="text"
              name="description"
              isInvalid={!!errors?.description}
              placeholder="Description..."
              value={localData?.description}
              onChange={(e) => handleChange(e, 'text')}
            />
              <Form.Control.Feedback type="invalid">
                    {errors?.description}
                  </Form.Control.Feedback>
          </Col>
             </Form.Group></>} */}
      </Form>
    );
  }
  function renderUpdateReel() {
    return (
      <Form>
        <Form.Group size='md' className='mb-2' as={Row}>
          <Col sm={2}>
            <Form.Label className='required'>Content Type</Form.Label>
          </Col>
          <Col sm={10}>
            <div className='d-flex gap-2'>
              <Form.Check
                type='radio'
                label='Image'
                name='contentType'
                value="image"  // ✅ Set actual value
                checked={contentType === "image"} // ✅ Check conditionally
                onChange={e => setContentType(e.target.value)}
                className='me-3'
                id="storyImage"
              />
              <Form.Check
                type='radio'
                label='Video'
                name='contentType'
                value="video"  // ✅ Set actual value
                checked={contentType === "video"} // ✅ Check conditionally
                onChange={e => setContentType(e.target.value)}
                id="storyVideo"
              />
            </div>
          </Col>
        </Form.Group>

        <Row>
          <Col sm='6'>
            <Form.Group as={Row} className='mb-4' controlId='formTitleInput'>
              <Form.Label className='required' column sm='4'>
                Title
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='title'
                  isInvalid={!!errors?.title}
                  className='form-control'
                  value={localData?.title}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>


          <Col sm='6'>
            <Form.Group as={Row} className='mb-4' controlId='formTitleHiInput'>
              <Form.Label className='required' column sm='4'>
                TitleHi
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  placeholder='Title...'
                  name='titleHi'
                  isInvalid={!!errors?.titleHi}
                  className='form-control'
                  value={localData?.titleHi}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.titleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>


        <Row>
          <Col sm='6'>
            <Form.Group as={Row} className='mb-4' controlId='formDescriptionInput'>
              <Form.Label className='required' column sm='4'>
                Description
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 60 }}
                  name='description'
                  isInvalid={!!errors?.description}
                  placeholder='Description...'
                  className='form-control'
                  value={localData?.description}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.description}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
          <Col sm='6'>
            <Form.Group as={Row} className='mb-4' controlId='formDescriptionHiInput'>
              <Form.Label className='required' column sm='4'>
                DescriptionHi
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  as='textarea'
                  style={{ height: 60 }}
                  name='descriptionHi'
                  isInvalid={!!errors?.descriptionHi}
                  placeholder='Description...'
                  className='form-control'
                  value={localData?.descriptionHi}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.descriptionHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>

        {/* {localData?.slug ? (
          <Form.Group as={Row} className='mb-3' controlId=''>
            <Form.Label className='' column sm='2'>
              Slug
            </Form.Label>
            <Col sm='6'>{localData?.slug}</Col>
          </Form.Group>
        ) : (
          <> */}

        <Row>
          <Col sm='6'>
            <Form.Group as={Row} className='mb-0' controlId=''>
              <Form.Label className='required' column sm='4'>
                Slug
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  type='text'
                  name='slug'
                  placeholder='Slug...'
                  className='form-control '
                  isInvalid={!!errors?.slug}
                  value={localData?.slug}
                  onChange={e => handleChange(e, 'slug')}
                  onBlur={e => {
                    const slugifiedValue = SlugifyStringWithDash(e.target.value);
                    handleChange({ target: { name: 'slug', value: slugifiedValue } }, 'slug');
                  }}
                />
                <Form.Control.Feedback type='invalid'>{errors?.slug}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
          <Col sm='6'>
            {
              contentType === 'image' ? (
                <Form.Group as={Row} className='mb-0' controlId=''>
                  <Form.Label className='required' column sm='4'>
                    Image
                  </Form.Label>
                  <Col sm='8'>
                    <label id='labimg'>
                      <img
                        className='imgs'
                        src={imagePreview ? imagePreview : localData?.reelImage ? localData?.reelImage : upimg}
                        width='100%'
                        alt=''
                      />

                      <Form.Control
                        type='button'
                        name='reelImage'
                        isInvalid={!!errors?.reelImage}
                        onClick={e => {
                          handleOpenModal('reelImage');
                        }}
                      />
                      <Form.Control.Feedback type='invalid'>{errors?.reelImage}</Form.Control.Feedback>
                    </label>
                  </Col>
                </Form.Group>
              ) : (

                <Form.Group as={Row} className='mb-0' controlId='formReelInput'>
                  <Form.Label column sm='4' className='required'>
                    Video File
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='file'
                      name='video'
                      isInvalid={!!errors?.video}
                      onChange={previewVideo}
                      accept='.mov,.mp4'
                      placeholder=''
                      className='profile-aud'
                    />
                    {source && <video className='' width='150' height='120' controls src={source} />}
                    <Form.Control.Feedback type='invalid'>{errors?.video}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

              )
            }
          </Col>
        </Row>


        {/* </>
        )} */}



        <hr style={{ color: 'brown', margin: '1.5rem 0px' }} />
        <Row className='mt-3'>
          <h6>SEO Details :</h6>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-4' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Title
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaTitle}
                  name='metaTitle'
                  className='form-control'
                  placeholder='Meta Title...'
                  value={localData?.metaTitle}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaTitle}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-4' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Title (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaTitleHi}
                  name='metaTitleHi'
                  className='form-control'
                  placeholder='Meta Title (Hindi)...'
                  value={localData?.metaTitleHi}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaTitleHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Description
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaDescription}
                  name='metaDescription'
                  className='form-control'
                  placeholder='Meta Description...'
                  value={localData?.metaDescription}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaDescription}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Description (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaDescriptionHi}
                  name='metaDescriptionHi'
                  className='form-control'
                  placeholder='Meta Description (Hindi)...'
                  value={localData?.metaDescriptionHi}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaDescriptionHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Keywords
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaKeyWords}
                  name='metaKeyWords'
                  className='form-control'
                  placeholder='Meta Keywords...'
                  value={localData?.metaKeyWords}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaKeyWords}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>

          <Col sm={6}>
            <Form.Group as={Row} className='mb-3' controlId=''>
              <Form.Label className='required' column sm='4'>
                Meta Keywords  <br />  (Hindi)
              </Form.Label>
              <Col sm='8'>
                <Form.Control
                  as='textarea'
                  style={{ height: 80 }}
                  isInvalid={!!errors?.metaKeyWordsHi}
                  name='metaKeyWordsHi'
                  className='form-control'
                  placeholder='Meta Keywords (Hindi)...'
                  value={localData?.metaKeyWordsHi}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.metaKeyWordsHi}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        </Row>
        {/* <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Tags
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="tags"
                placeholder="Add tags saprated by comma Ex. Tag1,Tag2"
                value={localData?.tags}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Category
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="category"
                placeholder="Category"
                value={localData?.category}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formReelInput">
            <Form.Label column sm="3">
            Hash Tags
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                name="hash_tags"
                placeholder="Add hast tags saprated by comma Ex. #Tag1,#Tag2"
                value={localData?.hash_tags}
                onChange={(e) => handleChange(e, 'text')}
              />
            </Col>
            </Form.Group> */}
      </Form >
    );
  }

  function renderAddReelURL() {
    return (
      <Form>
        <>
          <Form.Group as={Row} className='mb-3' controlId='formReelInput'>
            <Form.Label column sm='3'>
              Category
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                type='text'
                name='category'
                placeholder='Category'
                value={localData?.category}
                onChange={e => handleChange(e, 'text')}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className='mb-3' controlId='formReelInput'>
            <Form.Label column sm='3' className='required'>
              URL's
            </Form.Label>
            <Col sm='9'>
              <Form.Control
                as='textarea'
                name='urls'
                placeholder='Add url of reels (Comma-separated)...'
                defaultValue={localData?.urls}
                isInvalid={!!errors?.urls}
                style={{ height: 300 }}
                onChange={e => handleChange(e, 'text')}
              />
              <Form.Control.Feedback type='invalid'>{errors?.urls}</Form.Control.Feedback>
            </Col>
          </Form.Group>
        </>
      </Form>
    );
  }

  function renderUpdateChalisa() {
    footerContent = () => {
      return (
        <>
          {loader
            ? footerButtonloader.buttonState && (
              <Button variant='success' className='as_btn'>
                {footerButtonloader.buttonName}
              </Button>
            )
            : footerButton.buttonState && (
              <Button variant='success' className='as_btn' onClick={() => onButtonClick(footerButton.buttonName)}>
                {footerButton.buttonName}
              </Button>
            )}
        </>
      );
    };
    return (
      <Form>
        <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
          <Form.Label column sm='3' className='required'>
            Title
          </Form.Label>
          <Col sm='9'>
            <Form.Control
              type='text'
              placeholder='Title...'
              name='title'
              isInvalid={!!errors?.title}
              value={localData?.title}
              onChange={e => handleChange(e, 'text')}
            />
            <Form.Control.Feedback type='invalid'>{errors?.title}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        {(localData?.content?.length &&
          localData?.content?.map((item, i) => {
            return (
              <>
                <Form.Group as={Row} className='mb-3' controlId={`formSubTitle-${i}`}>
                  <Form.Label column sm='3' className='required'>
                    Sub Title
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Control
                      type='text'
                      placeholder='Sub Title...'
                      name='subTitle'
                      isInvalid={!!errors?.content?.[i]?.subTitle}
                      value={item?.subTitle}
                      onChange={e => handleChange(e, 'text', i)}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.content?.[i]?.subTitle}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className='mb-3' controlId={`formSubContent-${i}`}>
                  <Form.Label column sm='3' className='required'>
                    Sub Content
                  </Form.Label>
                  <Col sm='9'>
                    <Form.Control
                      as='textarea'
                      name='subContent'
                      placeholder='Sub Content...'
                      isInvalid={!!errors?.content?.[i]?.subContent}
                      value={item?.subContent}
                      style={{ height: 150 }}
                      onChange={e => handleChange(e, 'text', i)}
                    />
                    <Form.Control.Feedback type='invalid'>{errors?.content?.[i]?.subContent}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {i != 0 ? (
                  <FaMinus
                    className=' mt-3 hd-cr'
                    style={{ marginLeft: '97%' }}
                    title='Remove Section'
                    onClick={() => onButtonClick('removeSection', i)}
                  />
                ) : null}
              </>
            );
          })) ||
          null}
        <div>
          <FaPlus
            className=' mt-3 hd-cr'
            style={{ marginLeft: '97%', marginBottom: '6px' }}
            title='Add Section'
            onClick={() => {
              onButtonClick('addSection');
            }}
          />
        </div>

        <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
          <Form.Label column sm='3' className='required'>
            Image
          </Form.Label>
          <Col sm='9'>
            <label id='labimg'>
              {cropimg ? (
                <img
                  src={cropimg}
                  onClick={() => setCropImg()}
                  // crossOrigin="anonymous"
                  className='imgs'
                  alt=''
                  width='100%'
                />
              ) : (
                <img
                  // crossOrigin="anonymous"
                  className='imgs'
                  alt=''
                  width='100%'
                  src={localData?.file ? localData?.file : upimg}
                />
              )}

              {cropimg ? (
                <input onClick={e => setShowCrop(true)} />
              ) : (
                <>
                  <Form.Control
                    //  type="file"
                    className='form-control '
                    name='file'
                    isInvalid={!!errors?.file}
                    // size="40"
                    onClick={e => {
                      setShowCrop(true);
                      handleRt(e);
                    }}
                    accept='image/png, image/gif, image/jpeg'
                    id='inputds'
                  />
                  <Form.Control.Feedback type='invalid'>{errors?.file}</Form.Control.Feedback>
                </>
              )}
            </label>
          </Col>
        </Form.Group>

        {modalHeading === 'Edit Mantra' || modalHeading === 'Add Mantra' ? (
          ''
        ) : (
          <>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label className='required' column sm='3'>
                Content Type
              </Form.Label>
              <Col sm='9'>
                <Form.Select
                  aria-label='Default select example'
                  required
                  className='hd-cr'
                  isInvalid={!!errors?.imageType}
                  name='imageType'
                  value={localData?.imageType}
                  onChange={e => handleChange(e, 'text')}
                >
                  <option value=''>Choose</option>
                  <option value='english'>English</option>
                  <option value='hindi'>Hindi</option>
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{errors?.imageType}</Form.Control.Feedback>
              </Col>
              {/* <Col sm="4"></Col> */}
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
              <Form.Label column sm='3'>
                Audio File
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='file'
                  name='audio'
                  onChange={e => handleChange(e, 'audio')}
                  accept='audio/*'
                  placeholder=''
                  className='profile-aud'
                />
                <span style={{ color: 'gray' }}>
                  {typeof localData.audio === 'object'
                    ? localData.audio.name
                    : localData.audio === 'https://d3i0p1mk3sd6q7.cloudfront.net/undefined'
                      ? ''
                      : localData.audio?.slice(26)}
                </span>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className='mb-3' controlId='formTitleInput'>
              <Form.Label column sm='3' className='required'>
                Song URL
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  placeholder='Song URL...'
                  isInvalid={!!errors?.songUrl}
                  name='songUrl'
                  value={localData?.songUrl}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.songUrl}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className='mb-3' controlId='formAartiInput'>
              <Form.Label column sm='3' className='required'>
                Description
              </Form.Label>
              <Col sm='9'>
                <Form.Control
                  type='text'
                  name='description'
                  isInvalid={!!errors?.description}
                  placeholder='Description...'
                  value={localData?.description}
                  onChange={e => handleChange(e, 'text')}
                />
                <Form.Control.Feedback type='invalid'>{errors?.description}</Form.Control.Feedback>
              </Col>
            </Form.Group>
          </>
        )}
      </Form>
    );
  }
  function renderAddMultiReel() {
    return (
      <Form>
        <Form.Group as={Row} className='mb-3'>
          <Form.Label className='' column sm='4'>
            Story Images
          </Form.Label>
        </Form.Group>

        <div className='reel-images-container'>
          {inputFields?.map((data, index) => {
            const { title, description,titleHi, descriptionHi,MReelImg, preview_url } = data;


            return (
              <div key={index} className='reel-image-item'>
                  <Row>
                  <Col sm='6'>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label className='' column sm='4'>
                    Title
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      name='title'
                      value={title}
                      onChange={evnt => handleChangeRL(index, evnt, 'text')}
                      isInvalid={!!errors?.reelmultiImgData?.[index]?.title}
                      placeholder='Enter title...'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors?.reelmultiImgData?.[index]?.title}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                </Col>

                <Col sm='6'>

                <Form.Group as={Row} className='mb-3'>
                  <Form.Label className='' column sm='4'>
                    Description
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      as='textarea'
                      name='description'
                      value={description}
                      onChange={evnt => handleChangeRL(index, evnt, 'text')}
                      style={{ height: 80 }}
                      isInvalid={!!errors?.reelmultiImgData?.[index]?.description}
                      placeholder='Enter description...'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors?.reelmultiImgData?.[index]?.description}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                </Col>
                </Row>
                <Row>
                <Col sm='6'>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label className='' column sm='4'>
                    TitleHi
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      type='text'
                      name='titleHi'
                      value={titleHi}
                      onChange={evnt => handleChangeRL(index, evnt, 'text')}
                      isInvalid={!!errors?.reelmultiImgData?.[index]?.titleHi}
                      placeholder='Enter titleHi...'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors?.reelmultiImgData?.[index]?.titleHi}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                </Col>
                <Col sm='6'>

                <Form.Group as={Row} className='mb-3'>
                  <Form.Label className='' column sm='4'>
                    DescriptionHi
                  </Form.Label>
                  <Col sm='8'>
                    <Form.Control
                      as='textarea'
                      name='descriptionHi'
                      value={descriptionHi}
                      onChange={evnt => handleChangeRL(index, evnt, 'text')}
                      style={{ height: 80 }}
                      isInvalid={!!errors?.reelmultiImgData?.[index]?.descriptionHi}
                      placeholder='Enter descriptionHi...'
                    />
                    <Form.Control.Feedback type='invalid'>
                      {errors?.reelmultiImgData?.[index]?.descriptionHi}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                </Col>
                </Row>
                <Form.Group as={Row} className='mb-3'>
                  <Form.Label className='' column sm='4'>
                    Upload Image
                  </Form.Label>

                  <Col sm='8'>
                    <label id='labimg'>
                      <Form.Control
                        className='form-control '
                        onClick={e => {
                          handleOpenModal('mReelImg', index);
                        }}
                        name='MReelImg'
                        isInvalid={!!errors?.reelmultiImgData?.[index]?.MReelImg}
                        size='40'
                        accept='image/png, image/gif, image/jpeg'
                        id='inputds'
                      />

                      <img
                        className='imgs'
                        src={preview_url ? preview_url : MReelImg ? MReelImg : upimg}
                        width='100%'
                        alt=''
                      />
                      <Form.Control.Feedback type='invalid'>
                        {errors?.reelmultiImgData?.[index]?.MReelImg}
                      </Form.Control.Feedback>
                    </label>
                  </Col>

                  <div>
                    {inputFields.length !== 1 ? (
                      <div
                        style={{
                          display: 'flex',
                          color: 'red',
                          alignItems: 'center',
                          justifyContent: 'end',
                          cursor: 'pointer',
                        }}
                      >
                        <Button onClick={() => removeInputFields(index)} className='Dt-btn add-btn1'>
                          Remove
                          <FaMinus className='hd-cr fw-bold' title='Remove Section' />
                        </Button>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "end", color: "green", cursor: 'pointer', }}>

                    <Button onClick={addInputField} className="Et-btn add-btn1 btn-success1">
                      Add
                      <FaPlus
                        className="hd-cr"
                        title="Add Section" />
                    </Button>

                  </div>
                </Form.Group>

                {/* Remove Button */}
                {/* <div className='remove-btn-container'>
                  {inputFields.length !== 1 && (
                    <Button onClick={() => removeInputFields(index)} className='btn btn-danger'>
                      Remove <FaMinus className='hd-cr fw-bold' />
                    </Button>
                  )}
                </div> */}
              </div>
            );
          })}
        </div>

        {/* Add Button */}
        {/* <div className='add-btn-container'>
          <Button onClick={addInputField} className='btn btn-success'>
            Add <FaPlus className='hd-cr' />
          </Button>
        </div> */}
      </Form>
    );
  }
  function renderReadMore() {
    footerButton.buttonState = false;
    return <pre style={{ textAlign: 'center' }}>{localData}</pre>;
  }

  function renderchalisaReadMore() {
    footerButton.buttonState = false;
    return (
      localData?.length &&
      localData?.map((item, i) => {
        return (
          <pre key={i} style={{ textAlign: 'center' }}>
            <p className='subtitle-b'>{item?.subTitle}</p>
            <p>{item?.subContent}</p>
          </pre>
        );
      })
    );
  }

  function renderModal() {
    switch (id) {
      case 'updateAarti':
        return renderUpdateAarti();
      case 'updateReel':
        return renderUpdateReel();
      case 'readMore':
        return renderReadMore();
      case 'addReelURL':
        return renderAddReelURL();
      case 'multiReelAdd':
        return renderAddMultiReel();
      case 'chalisaReadMore':
        return renderchalisaReadMore();
      case 'updateChalisa':
        return renderUpdateChalisa();
      default:
        return 'you need to give an Id...';
    }
  }

  return (
    <>
      <Modal
        show={show}
        onHide={() => onHide()}
        backdrop='static'
        size={size}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        scrollable
        className='marketing_model'
      >
        <Modal.Header closeButton>
          <Modal.Title id={id}>{modalHeading}</Modal.Title>
        </Modal.Header>

        <Modal.Body>{renderModal()}</Modal.Body>

        <Modal.Footer>
          {/* {
            footerButton.buttonState &&
            <Button
              variant="success"
              onClick={onButtonClick}
            >
              {footerButton.buttonName}
            </Button>
          } */}
          {footerContent()}
          <Button onClick={() => onHide()} className='as_btn reset-btn'>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <CropModal
        showcrop={showcrop}
        setShowCrop={setShowCrop}
        setCropImg={setCropImg}
        cropimg={cropimg}
        caartifileimage={caartifileimage}
        setFileCaartiimage={setFileCaartiimage}
      />
      <PujaImgGalleyModel
        id='ImgGalleyModel'
        show={showImageGalleyModel}
        onHide={() => setShowImageGalleyModel(false)}
        modalHeading={'ImgGalley'}
        size='lg'
        handleSetImageUrl={handleSetImageUrl}
        fieldKey={modalFieldKey}
        setClearSel={setClearSel}
        clearSel={clearSel}
      />
    </>
  );
}
